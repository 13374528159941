import { setPressed, setSub } from '../helpers';

const SUB_NAV_BTN = '.sub-nav__button';

const clickHandler = e => {
  e.preventDefault();
  const el = e.currentTarget;
  const pressed = setPressed(el);
  setSub(el, pressed);
};

const SetBtn = btn => {
  btn.setAttribute('aria-pressed', 'false');
  btn.addEventListener('click', clickHandler, false);
};

export default nav => {
  const btns = [...nav.querySelectorAll(SUB_NAV_BTN)];

  if (btns.length === 0) return;

  btns.forEach(SetBtn);
};
