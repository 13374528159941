const MENU_BTN = 'menu-button';
const NAV_WRAPPER = 'nav-wrapper';
const CLOSE_BTN = 'menu-close';
const BODY_CLASS = 'nav-open';

const addOpen = (closeBtn, nav) => e => {
  e.preventDefault();
  closeBtn.setAttribute('aria-pressed', 'false');
  e.target.setAttribute('aria-pressed', 'true');
  nav.setAttribute('aria-hidden', 'false');
  document.body.classList.add(BODY_CLASS);
};

const addClose = (openBtn, nav) => e => {
  e.preventDefault();
  openBtn.setAttribute('aria-pressed', 'false');
  e.target.setAttribute('aria-pressed', 'true');
  nav.setAttribute('aria-hidden', 'true');
  document.body.classList.remove(BODY_CLASS);
};

export default () => {
  const openBtn = document.getElementById(MENU_BTN);
  const navWrapper = document.getElementById(NAV_WRAPPER);

  const closeBtn = document.getElementById(CLOSE_BTN);

  if (!closeBtn && !openBtn) return;

  openBtn.addEventListener('click', addOpen(closeBtn, navWrapper));

  closeBtn.addEventListener('click', addClose(openBtn, navWrapper));
};
