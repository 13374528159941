import GetData from './data';
import MobileNav from './mobile';
import Format from './format';
import DesktopNav from './desktop';
import tracker from '../tracker';

const MAIN_NAV = 'main-nav';

const MOBILE_NAV = ['tablet', 'mobile'];

const shouldChange = (oldDevice, newDevice) => {
  if (oldDevice === newDevice) return false;
  if (MOBILE_NAV.includes(oldDevice) && MOBILE_NAV.includes(newDevice)) return false;
  return true;
};

const changeNav = async (nav, device) => {
  if (!shouldChange(nav.dataset.device, device)) return;
  let data = [];
  try {
    data = await GetData(nav);
  } catch (e) {
    console.log('An error', e);
  }

  document.body.classList.remove('nav-open');

  Format(nav, data, device);
  console.log('Device??', device);
  if (device === 'desktop') {
    console.log('Desktop nav');
    DesktopNav(nav);
  } else {
    MobileNav(nav);
  }
};

export default async () => {
  const mainNav = document.getElementById(MAIN_NAV);

  if (!mainNav) return;

  const ViewDetect = tracker(dev => {
    console.log('Tracking??', dev);
    changeNav(mainNav, dev);
    mainNav.dataset.device = dev;
  });

  const device = ViewDetect.getDevice();
  if (device === 'desktop') {
    DesktopNav(mainNav);
  } else {
    MobileNav(mainNav);
  }
};
