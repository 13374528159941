import { setPressed, setSub } from '../helpers';

const SUB_LINK = '.desktop-sub-nav .desktop-nav__link';

const hoverHandler = e => {
  const el = e.currentTarget;
  const pressed = setPressed(el);
  setSub(el, pressed);
};

const setUpLinks = link => {
  link.setAttribute('aria-pressed', false);

  link.addEventListener('mouseenter', hoverHandler);
};

export default mainNav => {
  const NavLinks = [...mainNav.querySelectorAll(SUB_LINK)];

  NavLinks.forEach(setUpLinks);
};
