// Carousel
const carouselElements = [...document.querySelectorAll('.carousel')];

if (carouselElements.length > 0) {
  import(/* webpackChunkName: "vanilla-carousel" */ '@morsedigital/vanilla-carousel').then(
    ({ default: CarouselClass }) => {
      CarouselClass();
    }
  );
}
// End Carousel
