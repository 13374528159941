export const getAttribute = (el, attr) => {
  const data = el.getAttribute(attr);
  if (/^\d+$/.test(data)) return parseFloat(data, 10);
  if (/^true$/.test(data)) return true;
  if (/^false$/.test(data)) return false;
  return data;
};

export const setPressed = el => {
  const pressed = getAttribute(el, 'aria-pressed');
  el.setAttribute('aria-pressed', !pressed);

  return pressed;
};

export const setSub = (el, pressed) => {
  const id = getAttribute(el, 'aria-controls');
  const sub = document.getElementById(id);
  if (!sub) return;
  sub.setAttribute('aria-hidden', pressed);
};
