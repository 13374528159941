import HomeIcon from '../../../images/home.png';

export const BookBtn = () => `
  <li class="main-nav__item main-nav__item--book">
    <a
      href="https://bookings.better.org.uk/location/london-aquatics-centre"
      accesskey="8"
      class="main-nav__button button-lac button-primary-small"
    >
      Book
    </a>
  </li>
`;

export const HomeBtn = () => `
  <li class="main-nav__item main-nav__item--home">
    <a href="/" accesskey="1" class="main-nav__link">
      <img src="${HomeIcon}" alt="home" />
      <span class="hidden">Home</span>
    </a>
  </li>
`;

export const subLink = ({ path, title }, lastClass = '') =>
  `
    <li class="sub-nav__item">
      <a href="${path}" class="sub-nav__link ${lastClass}">${title}</a>
    </li>
  `;
