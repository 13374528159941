// Collapsible Content
const collapsibleContentElements = document.querySelectorAll(
  ".collapsible-content"
);
if (collapsibleContentElements.length > 0) {
  import(
    /* webpackChunkName: "vanilla-collapsible-content" */ "@morsedigital/vanilla-collapsible-content"
  ).then(({ default: CollapsibleContent }) => {
    [...collapsibleContentElements].forEach(
      collapsibleContent =>
        new CollapsibleContent({
          element: collapsibleContent,
          bodyContainerClass: "collapsible-content-body",
          itemContainerClass: "collapsible-content-item",
          toggleContainerClass: "collapsible-content-toggle",
          expanded: true
        })
    );
  });
}
// End Collapsible Content
