const successCheck = ({ status }) => status >= 200 && status < 300;

export default async nav => {
  const url = nav.dataset.api;
  try {
    const response = await fetch(url);
    const isSuccess = successCheck(response);

    if (!isSuccess) return { error: response, isSuccess };

    const data = await response.json();

    return data.filter(({ title }) => title.toLowerCase() !== 'home');
  } catch (e) {
    return { error, isSuccess: false };
  }
};
