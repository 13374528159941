import { BookBtn, HomeBtn, subLink } from './shared';

const close = () => `<svg viewBox="0 0 3840 3840" xmlns="http://www.w3.org/2000/svg">
  <title>Close menu</title>
  <path
    fill="#fff"
    d="M2514 2346q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z"
  />
</svg>`;

const NavTitle = () => `
  <li class="main-nav__header">
    <h2 class="gg beta main-nav____title" id="menu-header">Menu</h2>
    <button class="main-nav__header-close" type="button" id="menu-close">
      ${close()}
    </button>
  </li>
`;

export const MenuBtn = () => `
  <button class="main-nav__open" type="button" id="menu-button" aria-controls="main-nav">
    <svg viewBox="0 0 3840 3840" xmlns="http://www.w3.org/2000/svg">
      <title>Open Menu</title>
      <path
        fill="#fff"
        d="M2688 2368v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"
      />
    </svg>
  </button>
`;

const MenuItem = ({ path, title }, accesskey) => `
  <li class="main-nav__item">
    <a href="${path}" accesskey="${accesskey}" class="main-nav__link">${title}</a>
  </li>
`;

const MenuWithSub = ({ id, path, sub, title }, accesskey) => `
  <li class="main-nav__item sub-nav" accesskey="${accesskey}">
    <div class="sub-nav__wrapper">
      <button class="sub-nav__button" aria-controls="sub-nav-${id}" id="sub-nav-btn-${id}">
        <span>${title}</span>
        <svg class="sub-nav__icon" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z"
            fill="#fff"
          />
        </svg>
      </button>
    </div>
    <ul class="sub-nav__holder" aria-hidden="true" id="sub-nav-${id}">
      <li class="sub-nav__item">
        <a href="${path}" class="sub-nav__link">${title} home</a>
      </li>
      ${sub.map((subItem, i) => subLink(subItem)).join('')}
    </ul>
  </li>
`;

const createItems = data =>
  data
    .map((item, i) => {
      if (item.sub) return MenuWithSub(item, i + 2);
      return MenuItem(item, i + 2);
    })
    .join('');

export default data => `
  ${MenuBtn()}
  <ul class="main-nav__inner-wrapper" aria-hidden="true" id="nav-wrapper">
    ${NavTitle()} ${HomeBtn()} ${createItems(data)} ${BookBtn()}
  </ul>
`;
