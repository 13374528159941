// Collapsible Content
const contactsElements = document.getElementById("new_contact_form");
if (contactsElements) {
  import(/* webpackChunkName: "vanilla-contacts" */ "./contacts").then(
    ({ default: Contact }) => {
      new Contact();
    }
  );
}
// End Collapsible Content
