import { BookBtn, HomeBtn, subLink } from './shared';

const MenuItem = ({ path, title }, accesskey) => `
  <li class="desktop-nav__item">
    <a href="${path}" accesskey="${accesskey}" class="desktop-nav__link>${title}</a>
  </li>
`;

const MenuWithSub = ({ id, path, sub, title }, accesskey) => `
  <li class="desktop-nav__item desktop-sub-nav">
    <a href="${path}" accesskey="${accesskey}" class="desktop-nav__link">${title}</a>
    <ul class="desktop-sub-nav__holder" aria-hidden="true" id="sub-nav-${id}">
      ${sub.map(subItem => subLink(subItem)).join('')}
    </ul>
  </li>
`;

const titleBlock = () => `
  <h3 class="hidden">Menu</h3>
`;

const createItems = data =>
  data
    .map((item, i) => {
      if (item.sub) return MenuWithSub(item, i + 2);
      return MenuItem(item, i + 2);
    })
    .join('');

export default data => `
  ${titleBlock()}
  <ul class="desktop-nav__inner-wrapper" id="nav-wrapper">
    ${HomeBtn()} ${createItems(data)} ${BookBtn()}
  </ul>
`;
