// import '@babel/polyfill';
import './pollyfills';

// import './load_service_worker';
import './font_observer';
import './carousel';
import './collapsible_content';
import './contact';
import Navigation from './navigation';
import { start as startUjs } from '@rails/ujs';
import lozad from 'lozad';
// See - https://github.com/morsedigital/morse-utils for details
import {
  // Modal, // Modal
  AlertsClear,
  AlertClose, // Adds alert close functionality
  AlertReset, // Adds reset - opens all alerts
  Cookiebar,
  EventListener,
  MoreLess,
} from '@morsedigital/morse-utils';

import SurveyBanner from './survey_banner';
import SurveyPopOut from './survey-pop-out';

// import { start as activestorage } from '@rails/activestorage'; // Only add if required

(() => {
  SurveyBanner();
  SurveyPopOut();
})();

(() => {
  startUjs(); // Start UJS
  // activestorage(); // Start Activestorage
})();

(() => {
  // Utility functions
  const modules = [
    AlertsClear(),
    AlertClose(),
    AlertReset(),
    Cookiebar('cookie-msg'), // Should be id of Cookie prompt DOM Node
    // Modal(),
    MoreLess(),
  ];
  EventListener(modules);
})();

(() => {
  Navigation();
})();

// Lazy load images
(() => {
  const observer = lozad(); // lazy loads elements with default selector as '.lozad'
  observer.observe();
})();
